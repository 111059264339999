<script setup lang="ts">
import { ref } from 'vue'

interface MediaItem {
  src: string
  type: 'image' | 'video'
}

const props = defineProps<{
  images: MediaItem[]
  width?: string
  height?: string
}>()

const showLightbox = ref(false)
const currentIndex = ref(0)

const openLightbox = (index: number) => {
  // if (props.images[index].type === 'image') {
  //   currentIndex.value = index
  //   showLightbox.value = true
  // }

  currentIndex.value = index
  showLightbox.value = true
}

const closeLightbox = () => {
  showLightbox.value = false
}

const nextImage = (e: Event) => {
  e.stopPropagation()
  if (currentIndex.value < props.images.length - 1) {
    currentIndex.value++
    // // 跳过视频
    // while (currentIndex.value < props.images.length && props.images[currentIndex.value].type === 'video') {
    //   currentIndex.value++
    // }
  }
}

const prevImage = (e: Event) => {
  e.stopPropagation()
  if (currentIndex.value > 0) {
    currentIndex.value--
    // // 跳过视频
    // while (currentIndex.value > 0 && props.images[currentIndex.value].type === 'video') {
    //   currentIndex.value--
    // }
  }
}

// 处理键盘事件
const handleKeydown = (e: KeyboardEvent) => {
  if (!showLightbox.value) return
  switch (e.key) {
    case 'ArrowLeft':
      prevImage(e)
      break
    case 'ArrowRight':
      nextImage(e)
      break
    case 'Escape':
      closeLightbox()
      break
  }
}
</script>

<template>
  <div class="grid-container">
    <div class="grid-wrapper">
      <div v-for="(item, index) in images" :key="index" class="grid-item">
        <!-- 图片展示 -->
        <img v-if="item.type === 'image'" :src="item.src" class="media-item" loading="lazy"
          @click="openLightbox(index)">

        <!-- 视频展示 -->
        <video v-if="item.type === 'video'" class="media-item" autoplay preload="metadata" muted
          @click="openLightbox(index)">
          <source :src="item.src" type="video/mp4">
        </video>
      </div>
    </div>
  </div>

  <!-- 灯箱效果 -->
  <div v-if="showLightbox" class="lightbox-container" @click="closeLightbox">
    <button v-if="currentIndex > 0" class="nav-button left-button" @click="prevImage">
      &#8249;
    </button>

    <img v-if="images[currentIndex].type === 'image'" :src="images[currentIndex].src" class="lightbox-media">

    <video v-if="images[currentIndex].type === 'video'"  :key="currentIndex" class="lightbox-media"  autoplay muted>
      <source :src="images[currentIndex].src" type="video/mp4">
    </video>

    <button v-if="currentIndex < images.length - 1" class="nav-button right-button" @click="nextImage">
      &#8250;
    </button>

    <button class="close-button" @click="closeLightbox">
      ×
    </button>
  </div>
</template>

<style scoped>
.grid-container {
  width: 100%;
  padding: 1rem;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
}

.grid-item {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  /* 1:1 宽高比 */
  overflow: hidden;
}

.media-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
}

img.media-item {
  cursor: pointer;
}

img.media-item:hover {
  transform: scale(1.05);
}

.lightbox-container {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox-media {
  max-height: 90vh;
  max-width: 90vw;
  object-fit: contain;
}

.nav-button {
  position: absolute;
  color: white;
  font-size: 2.5rem;
  padding: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: #d1d5db;
}

.left-button {
  left: 1rem;
}

.right-button {
  right: 1rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 2.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #d1d5db;
}

@media (max-width: 640px) {
  .grid-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
</style>
